import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import BlurhashImage from "../components/Utils/BlurhashImage";
import Modal from "react-modal";
import ReactPlayer from "react-player/youtube";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import "../styles/_tabs.scss";

import UserContext from "../components/Auth/UserContext";
import AuthContent from "../components/Auth/AuthContent";
import { AppStatusContext } from "../components/Utils/AppStatus";

import HeroItem from "../components/Hero/Item";
import ModalHeader from "../components/Blocks/ModalHeader";
import ModalImage from "../components/Blocks/ModalImage";
import ItemActions from "../components/Blocks/ItemActions";
import ItemDetails from "../components/Blocks/ItemDetails";
import ItemSeasons from "../components/Blocks/ItemSeasons";
import ItemGenres from "../components/Blocks/ItemGenres";
import People from "../components/Items/People";
import Posters from "../components/Items/Posters";
import Collections from "../components/Items/Collections";

import Reviews from "../components/Items/ItemReviews";
import ExternalReviews from "../components/Blocks/ExternalReviews";
import UserLists from "../components/Blocks/UserLists";
import ReviewItem from "../components/Blocks/ReviewItem";
import RateItem from "../components/Blocks/RateItem";
import Streaming from "../components/Blocks/Streaming";

import { ChevronRight, MoreHorizontal } from "react-feather";

import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";

import poster from "../img/poster_lg.png";

import "./Item.scss";

dayjs.extend(relativeTime);

const Item = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const descRef = useRef(null);
  const [modalProps, setModalProps] = useState({});
  const { appLoading, setAppLoading, userCountry, userCountryName } =
    useContext(AppStatusContext);

  // Tabs
  const tabsRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);
  function handleTabSelect(index) {
    setTabIndex(index);
    // tabsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  /**
   * Item data
   */

  const [item, setItem] = useState({});

  // Modals
  const [currentModal, setCurrentModal] = useState(false);

  /**
   * User data
   */
  const { user, isAuthenticated, isLoading } = useContext(UserContext);
  const [userData, setUserData] = useState({});

  function handleUserData(key, value) {
    setUserData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
    localStorage.removeItem("userProfile");
    localStorage.removeItem("userProfile_expiration_time");
  }

  // Define item URL
  const url =
    process.env.REACT_APP_API_URL +
    "/item/?token=" +
    process.env.REACT_APP_API_KEY +
    "&detail=true&id=" +
    id;

  // Load item
  useEffect(() => {
    fetch(url, { cache: "default" })
      .then((response) => response.json())
      .then((data) => {
        if (data.status !== "error") {
          setItem(data.item);
          setAppLoading(false);
        } else {
          setItem({});
          navigate("/error");
        }
      })
      .catch(function (error) {
        setItem({});
        console.log(error);
      });
  }, [url, appLoading, setAppLoading]);

  // User + item data
  useEffect(() => {
    // Get user /item data
    if (item && item.id && !appLoading && !isLoading) {
      if (isAuthenticated) {
        const userUrl = // Define item URL
          process.env.REACT_APP_API_URL +
          "/user/item/?token=" +
          process.env.REACT_APP_API_KEY +
          "&item=" +
          item.id +
          "&user=" +
          user.sub;

        fetch(userUrl, { cache: "default" })
          .then((response) => response.json())
          .then((data) => {
            if (data.status !== "error") {
              setUserData(data.results);
            }
          })
          .catch(function (error) {
            setUserData({});
            console.log(error);
          });
      }
    }
  }, [item, isLoading, appLoading, isAuthenticated, user]);

  // Modal props
  useEffect(() => {
    if (item && item.poster && item.title) {
      setModalProps({
        modal: {
          preventScroll: true,
          closeTimeoutMS: 300,
          ariaHideApp: false,
          onRequestClose: () => {
            setCurrentModal(false);
          },
        },
        header: {
          poster: item.poster,
          subtitle: item.title,
          onClose: () => {
            setCurrentModal(false);
          },
        },
      });
    }
  }, [item]);

  // Handle item data
  function handleItemData(key, value) {
    setItem((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }

  // Set release date and difference
  const [seasonRelease, setSeasonRelease] = useState(false);
  const [nearRelease, setNearRelease] = useState(false);
  const [releaseString, setReleaseString] = useState(false);

  useEffect(() => {
    let currentString = false;

    // Next release is a season?
    if (item.seasons_detail && item.seasons_detail.length > 1) {
      let last = Object.keys(item.seasons_detail)[
        Object.keys(item.seasons_detail).length - 1
      ];
      Math.abs(dayjs().diff(item.seasons_detail[last].date, "days")) < 7 &&
        setSeasonRelease(item.seasons_detail[last].date);
    }

    seasonRelease
      ? setNearRelease(seasonRelease)
      : item.date && Math.abs(dayjs().diff(item.date, "days")) < 7
      ? setNearRelease(item.date)
      : setNearRelease(false);

    nearRelease
      ? dayjs().isSame(dayjs(nearRelease), "day")
        ? (currentString = "Releases today")
        : dayjs().isAfter(dayjs(nearRelease), "day")
        ? dayjs().diff(nearRelease, "days") === 0
          ? (currentString = "Released yesterday")
          : (currentString = "Released " + dayjs().to(dayjs(nearRelease)))
        : dayjs().diff(nearRelease, "days") === 0
        ? (currentString = "Premieres tomorrow")
        : (currentString = "Premiere " + dayjs().to(dayjs(nearRelease)))
      : (currentString = dayjs().to(dayjs(item.date)));

    seasonRelease &&
      currentString &&
      (currentString = "New season " + currentString);

    setReleaseString(currentString);
  }, [item, nearRelease, seasonRelease]);

  // Description height
  const [descHeight, setDescHeight] = useState(0);
  const [descStatus, setDescStatus] = useState("closed");
  useEffect(() => {
    if (descRef.current)
      setDescHeight(descRef.current.getBoundingClientRect().height);
  }, [descRef, appLoading]);

  /**
   * Reset stuff on path change
   */

  useEffect(() => {
    setItem({});
    setUserData({});
    setAppLoading(true);
    setDescHeight(0);
    setDescStatus("closed");
    setCurrentModal(false);
    setTabIndex(0);
    setReleaseString(false);
  }, [pathname]);

  return (
    <section id="Item">
      {!appLoading && item.type && (
        <Helmet>
          <title>
            {item.title} ({item.type[0].toUpperCase() + item.type.slice(1)}
            {item.year !== null ? `, ${item.year}` : ""}) &middot; Horror.ar
          </title>
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_URL}/item/${id}`}
          />
          {item.description && (
            <meta name="description" content={item.description} />
          )}
          {item.cover && (
            <meta
              property="og:image"
              content={`https://ik.imagekit.io/${process.env.REACT_APP_CDN_ID}/tr:n-cover_lg/${item.cover}`}
            />
          )}
        </Helmet>
      )}

      <ItemActions
        item={item}
        userData={userData}
        setCurrentModal={setCurrentModal}
      />

      <HeroItem item={item} type="film" isLoading={appLoading} />

      {/**
       * Modals
       */}
      <Modal {...modalProps.modal} isOpen={currentModal === "modalRate"}>
        <ModalHeader {...modalProps.header} title={"Rate this " + item.type} />
        <AuthContent>
          <RateItem
            item={item}
            userData={userData}
            handler={handleUserData}
            itemHandler={handleItemData}
          />
        </AuthContent>
      </Modal>

      <Modal {...modalProps.modal} isOpen={currentModal === "modalReview"}>
        <ModalHeader
          {...modalProps.header}
          title={"Review this " + item.type}
        />
        <AuthContent>
          <ReviewItem
            item={item}
            userData={userData}
            handleUserData={handleUserData}
          />
        </AuthContent>
      </Modal>

      <Modal {...modalProps.modal} isOpen={currentModal === "modalLists"}>
        <ModalHeader {...modalProps.header} title="Add or remove from lists" />
        <AuthContent>
          <UserLists
            item={item}
            userData={userData}
            handleUserData={handleUserData}
          />
        </AuthContent>
      </Modal>

      {item.trailer && (
        <Modal
          {...modalProps.modal}
          isOpen={currentModal === "modalTrailer"}
          className="trailer"
        >
          <ModalHeader {...modalProps.header} title="Trailer" />
          <ReactPlayer
            url={"https://www.youtube.com/watch?v=" + item.trailer}
            className="video"
            playing={true}
            controls={true}
          />
        </Modal>
      )}

      {item.poster && (
        <ModalImage
          isOpen={currentModal === "modalPoster"}
          image={{
            src: item.poster,
            alt: item.title,
            blurhash: item.poster_blurhash ?? null,
            width: 600,
            height: 900,
          }}
          onClick={() => {
            setCurrentModal(false);
          }}
        />
      )}

      <div
        className={"ItemDescription " + descStatus}
        style={{ "--desc-height": descHeight + "px" }}
      >
        {item.poster ? (
          <figure
            className="blurhash"
            onClick={() => {
              setCurrentModal("modalPoster");
            }}
          >
            <BlurhashImage
              src={item.poster}
              alt={item.title}
              t="poster_lg"
              blurhash={item.poster_blurhash ?? null}
              width={240}
              height={360}
            />
          </figure>
        ) : (
          <figure>
            <img src={poster} alt="" />
          </figure>
        )}

        <div className="text" ref={descRef}>
          {!appLoading && (
            <>
              {userCountry && item.released && (
                <Streaming
                  item={item}
                  country={userCountry.toUpperCase()}
                  countryName={userCountryName}
                />
              )}
              {nearRelease && (
                <p className="release tSubtitle">{releaseString}</p>
              )}
              {item.description ? (
                <p dangerouslySetInnerHTML={{ __html: item.description }} />
              ) : (
                <p className="empty">
                  We don't have a description for this {item.type} yet.
                </p>
              )}
            </>
          )}
        </div>
        {!appLoading && descHeight > 192 && (
          <button
            className="icon showMore"
            onClick={() => {
              setDescStatus("open");
            }}
          >
            <MoreHorizontal />
          </button>
        )}
      </div>

      {!appLoading && (
        <>
          {item.released &&
            item.external_scores &&
            item.external_scores.imdb !== null && (
              <>
                <div className="ItemScores">
                  {Object.entries(item.external_scores).map(
                    ([k, v], i) =>
                      v !== null && (
                        <a
                          key={i}
                          target="_blank"
                          href={v.source}
                          rel="noreferrer"
                        >
                          <img
                            //src={`/img/${k.toLowerCase().replace(/ /g, "-")}-2x.png`}
                            src={`https://logo.clearbit.com/${new URL(
                              v.source
                            ).hostname.replace(/^www\./, "")}`}
                            alt={k}
                            width="24"
                            height="24"
                            loading="lazy"
                          />
                          <span>{v.value * 10}%</span>
                        </a>
                      )
                  )}
                </div>
              </>
            )}

          <hr className="invisible" ref={tabsRef} />
          <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
            <TabList>
              {item.people?.cast && <Tab>Cast</Tab>}
              {(item.people?.crew || item.people?.production) && (
                <Tab>Crew</Tab>
              )}

              <Tab>Details</Tab>
              {item.seasons_detail && (
                <Tab>
                  {item.seasons} Season{item.seasons > 1 && "s"}
                </Tab>
              )}
              <Tab>Genres</Tab>
            </TabList>

            <SwipeableViews index={tabIndex} onChangeIndex={handleTabSelect}>
              {item.people?.cast && (
                <TabPanel>
                  <People items={item.people.cast} limit={5} />
                </TabPanel>
              )}

              {(item.people?.crew || item.people?.production) && (
                <TabPanel>
                  {item.people?.crew && (
                    <People items={item.people.crew} limit={5} />
                  )}
                  {item.people?.production && (
                    <People items={item.people.production} limit={5} />
                  )}
                </TabPanel>
              )}

              <TabPanel>
                <ItemDetails item={item} />
              </TabPanel>
              {item.seasons_detail && (
                <TabPanel>
                  <ItemSeasons item={item} />
                </TabPanel>
              )}

              <TabPanel>
                <ItemGenres
                  item={item}
                  handleItemData={handleItemData}
                  userData={userData}
                  handleUserData={handleUserData}
                />
              </TabPanel>
            </SwipeableViews>
          </Tabs>

          {item.released && (
            <>
              <h2 className="cSubtitle">
                <span className="click">
                  User reviews
                  {item.reviews &&
                    item.reviews.total > item.reviews.top.length && (
                      <ChevronRight />
                    )}
                </span>
              </h2>
              {item.reviews?.top?.[0] ? (
                <Reviews
                  item={item}
                  handleItemData={handleItemData}
                  userData={userData}
                  handleUserData={handleUserData}
                />
              ) : (
                <div className="emptyContent">
                  <p>
                    <strong>{item.title}</strong> has no reviews yet.
                  </p>
                  <p>
                    <button
                      className="outline"
                      onClick={() => {
                        setCurrentModal("modalReview");
                      }}
                    >
                      Add the first review
                    </button>
                  </p>
                </div>
              )}

              {item.external_reviews?.[0] && (
                <>
                  <h2 className="cSubtitle">External reviews</h2>
                  <ExternalReviews reviews={item.external_reviews} limit={3} />
                </>
              )}
            </>
          )}

          {item.collections && (
            <>
              <h2 className="cSubtitle">Collections</h2>
              <Collections items={item.collections} />
            </>
          )}

          {item.lists && (
            <>
              <h2 className="cSubtitle">Featured lists</h2>
              <Collections items={item.lists} />
            </>
          )}

          {item.similar && (
            <>
              <h2 className="cSubtitle">
                <span
                  className="click"
                  onClick={() => {
                    setCurrentModal("modalSimilar");
                  }}
                >
                  Similar items
                  <ChevronRight />
                </span>
              </h2>
              <Posters items={item.similar.slice(0, 10)} horizontal={true} />
              <Modal
                {...modalProps.modal}
                isOpen={currentModal === "modalSimilar"}
              >
                <ModalHeader {...modalProps.header} title="Similar items" />
                <Posters items={item.similar} />
              </Modal>
            </>
          )}
        </>
      )}
    </section>
  );
};

export default Item;
